import axios from 'axios'
import api from '../config/api'
import { param } from 'jquery'

export async function getLanguage(
  id = '',
  filter = [],
  limit = 10,
  offset = 1
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/languages`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/languages/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getDifficulty(
  id = '',
  filter = [],
  limit = 99,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/difficulties`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/difficulties/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function getDifficultyPaging(
  id = '',
  filter = [],
  limit = 10,
  offset = 1
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/difficulties`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/difficulties/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getQuestionType(
  id = '',
  filter = [],
  limit = 99,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/question-types`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/question-types/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getLevel(id = '', filter = [], limit = 10, offset = 1) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/levels`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/levels/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getXp(
  id = '',
  filter = [],
  limit = 10,
  offset = 1,
  level
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'grade[limit]': limit,
    'grade[offset]': offset,
    'grade-filter[level_id]': level,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/activity/gamification-xps`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(
      `${api.url}/activity/gamification-xps/${id}`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getStars(id = '', filter = [], limit = 10, offset = 1) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
    'filter[country]': region_id,
    'filter[school_id]': school_id,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/activity/gamification-stars`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(
      `${api.url}/activity/gamification-stars/${id}`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getVouchers(
  id = '',
  filter = [],
  limit = 10,
  offset = 1
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(
      `${api.url}/activity/gamification-vouchers`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(
      `${api.url}/activity/gamification-vouchers/${id}`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getLevels(id = '', filter = [], limit = 99, offset = 0) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'contains') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(
      `${api.url}/activity/gamification-avatars`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(
      `${api.url}/activity/gamification-avatars/${id}`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function postStar(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(
    `${api.url}/activity/gamification-stars`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}
export async function deleteStar(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/activity/gamification-stars/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}
export async function postLevel(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(
    `${api.url}/activity/gamification-avatars`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}
export async function editLevel(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/activity/gamification-avatars/${data._id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Edited!' }
  } else {
    return { status: 'error', msg: "Can't edit data" }
  }
}
export async function deleteLevel(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/activity/gamification-avatars/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}

export async function getGradeMaster(
  id = '',
  filter = [],
  limit = 10,
  offset = 1
) {

  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')

  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
    'page[sort]': 'order',
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/grades`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/grades/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function updateIndexDNDMaster(id = '', data = null, type, typeSub = null, idParent = null) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  const params = {}
  if (typeSub !== null) {
    Object.assign(params, {
      'filter[parent_id]': idParent,
    })
  }

  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
    params: params
  }
  const request = await axios.put(
    `${api.url}/content/${type}/order`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok' }
  } else {
    return { status: 'error', msg: "Can't load data" }
  }
}

export async function getCountry(id = '') {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
    },
  }
  if (id === '') {
    const request = await axios.get(`${api.url}/directory/countries`, config)
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function getSchool(id = '', filter = []) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/directory/schools`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/directory/schools/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getGrade(id = '', filter = []) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')

  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {
    'page[limit]': 9999
  }
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/grades`, {
      ...config,
      params: {
        ...params,
      },
    })
    if (request.status === 200) {
      return new Promise((resolve) =>
        resolve({ status: 'ok', data: request.data.data })
      )
    } else {
      return new Promise((resolve) =>
        resolve({ status: 'error', msg: "Can't load data" })
      )
    }
  }
}

export async function getSubject(id = '', filter = [], limit = 10, offset = 1) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'search') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
    'page[sort]': 'order',
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/subjects`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/subjects/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getTopic(id = '', filter = [], limit = 10, offset = 1) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'search') {
      Object.assign(params, { search: filter[item] })
    } else if (item === 'only_topic') {
      Object.assign(params, { 'only-topic': filter[item] })
    } else if (item === 'only_subtopic') {
      Object.assign(params, { 'only-subtopic': filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'filter[country]': region_id,
    'filter[school_id]': school_id,
    'page[limit]': limit,
    'page[offset]': offset,
    // 'page[sort]': 'order',
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/topics`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/topics/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getTopicByGrade(id = '', filter = [], limit = 10, offset = 1) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'search') {
      Object.assign(params, { search: filter[item] })
    } else if (item === 'only_topic') {
      Object.assign(params, { 'only-topic': filter[item] })
    } else if (item === 'only_subtopic') {
      Object.assign(params, { 'only-subtopic': filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'filter[country]': region_id,
    'filter[school_id]': school_id,
    'page[limit]': limit,
    'page[offset]': offset,
    'page[sort]': 'order',
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/topics-by-grades`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/topics/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getQuestion(
  id = '',
  filter = [],
  limit = 10,
  offset = 1
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'search') {
      Object.assign(params, { search: filter[item] })
    } else if (item === 'topic_parent_id') {
      Object.assign(params, { topic_parent_id: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })

  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/questions`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/questions/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getQuestionReport(
  id = '',
  filter = [],
  limit = 10,
  offset = 1,
  sort = '-created_at'
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'search') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
    'page[sort]': sort,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/activity/error-reports`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/activity/error-reports/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function markQuestionReport(id, data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      // School: school_id,
      // Country: region_id
    },
  }
  const request = await axios.put(
    `${api.url}/activity/error-reports/${id}`,
    data,
    config
  )
  if (request.status === 200) {
    return {
      status: 'ok',
      data: request.data.data,
      msg: 'Question mark fixed!',
    }
  } else {
    return { status: 'error', msg: "Can't edit data" }
  }
}

export async function getProfile() {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
    },
  }
  const request = await axios.get(`${api.url}/directory/profile/me`, config)
  if (request.status === 200) {
    localStorage.setItem('profile', JSON.stringify(request.data.data))
    return { status: 'ok', data: request.data.data }
  } else {
    return { status: 'error', msg: "Can't load data" }
  }
}

export async function postGrade(name, level, country, school_id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id_param = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id_param,
      Country: region_id,
    },
  }
  const request = await axios.post(
    `${api.url}/content/grades`,
    {
      name,
      level,
      country,
      school_id,
    },
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}
export async function updateGrade(id, { name, level_id, country, school_id }) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id_param = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id_param,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/grades/${id}`,
    {
      name,
      level_id,
      country,
      school_id,
    },
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Grade Updated!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deleteGrade(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(`${api.url}/content/grades/${id}`, config)
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}

export async function postSubject(name, country, school_id, grade_id, orderList) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id_param = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id_param,
      Country: region_id,
    },
  }
  const request = await axios.post(
    `${api.url}/content/subjects`,
    {
      name,
      country,
      school_id,
      grade_id,
      'order': orderList
    },
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Subject Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function updateSubject(
  id,
  { name, country, school_id, grade_id, order }
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id_param = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id_param,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/subjects/${id}`,
    {
      name,
      country,
      school_id,
      grade_id,
      order
    },
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Subject Updated!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deleteSubject(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/content/subjects/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}

export async function postTopic(
  name,
  country,
  school_id,
  grade_id,
  subject_id,
  tags = [],
  parent_id = '',
  orderList
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id_param = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id_param,
      Country: region_id,
    },
  }
  const request = await axios.post(
    `${api.url}/content/topics`,
    {
      name,
      country,
      school_id,
      grade_id,
      subject_id,
      tags,
      parent_id,
      'order': orderList
    },
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Topic Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function updateTopic(
  id,
  { name, country, school_id, grade_id, subject_id, tags = [], parent_id = '' }
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id_param = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id_param,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/topics/${id}`,
    {
      name,
      country,
      school_id,
      grade_id,
      subject_id,
      tags,
      parent_id,
    },
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Topic Updated!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deleteTopic(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(`${api.url}/content/topics/${id}`, config)
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}

export async function postQuestion(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(`${api.url}/content/questions`, data, config)
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function postUploadFile(data) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(`${api.url}/file/files`, data, config)
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function putQuestion(id = '', data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/questions/${id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Updated!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deleteQuestion(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/content/questions/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}
//Question Story
export async function getQuestionStoryDatatable(
  id = '',
  filter = [],
  limit = 99,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'contains') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/question-stories`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(
      `${api.url}/content/question-stories/${id}`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function getQuestionStory(id = '', filter = []) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/question-stories`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(
      `${api.url}/content/question-stories/${id}`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function postQuestionStory(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(
    `${api.url}/content/question-stories`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function putQuestionStory(id = '', data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/question-stories/${id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Updated!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

//Passage
export async function getPassage(id = '', filter = [], limit = 99, offset = 0) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'search') {
      Object.assign(params, { 'filter[name]': filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/passages`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/passages/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function postPassage(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id
    },
  }
  const request = await axios.post(`${api.url}/content/passages`, data, config)
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deletePassage(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/content/passages/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}

export async function editPassage(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id
    },
  }
  const request = await axios.put(
    `${api.url}/content/passages/${data._id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deleteQuestionStory(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/content/question-stories/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}
//End Question Story

//Theory
export async function getTheoryDatatable(
  id = '',
  filter = [],
  limit = 99,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'contains') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/theories`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/theories/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function getTheory(id = '', filter = []) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/theories`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/theories/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function postTheory(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(`${api.url}/content/theories`, data, config)
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function putTheory(id = '', data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/theories/${id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Updated!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deleteTheory(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/content/theories/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}

//Question Sets
export async function getQuestionSet(id = '', filter = [], limit = 10) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    let key = `filter[${item}]`
    if (item === 'limit_only') {
      key = 'limit-only'
    }

    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/question-sets`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data, ...request.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/question-sets/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
//Datatable List -ICHWAN- -DANDI-
export async function getQuestionSetList(
  id = '',
  filter = [],
  limit = 10,
  offset = 1
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    let key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
    'page[sort]': '-created_at',
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/question-sets`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/question-sets/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function postQuestionSet(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(
    `${api.url}/content/question-sets`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function putQuestionSet(id = '', data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/question-sets/${id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Updated!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deleteQuestionSet(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/content/question-sets/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}

export async function fetchLevel(id = '', filter = []) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/levels`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/levels/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

/**
 * Lesson
 */
export async function getLessonList(
  id = '',
  filter = [],
  limit = 99,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'contains') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'filter[country]': region_id,
    'filter[school_id]': school_id,
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/lessons`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/lessons/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function postLesson(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(`${api.url}/content/lessons`, data, config)
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}
export async function editLesson(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/lessons/${data._id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}
export async function deleteLesson(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(`${api.url}/content/lessons/${id}`, config)
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}
/**
 * Lesson End
 */

/**
 * Course
 */
export async function getCourseList(
  id = '',
  filter = [],
  limit = 99,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'contains') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/courses`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/courses/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function postCourse(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(`${api.url}/content/courses`, data, config)
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}
export async function deleteCourse(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(`${api.url}/content/courses/${id}`, config)
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}
export async function editCourse(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/courses/${data._id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}
/**
 * Course End
 */

/**
 * Lesson Prerequisite
 */
export async function postLessonPrerequisite(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/courses/lesson-paths`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

// remove lessonPath
export async function deleteLessonPath(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  config.data = data

  const request = await axios.delete(
    `${api.url}/content/courses/lesson-paths`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}
/**
 * End Lesson Prerequisite
 */

/**
 * Post files image
 */
export async function postImage(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(
    `${api.url}/file/files`,
    data
    // config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

/**
 * PACKAGE
 */
export async function getPackagesMainList(
  id = '',
  filter = [],
  limit = 99,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'contains') {
      Object.assign(params, { 'filter[name]': filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/commerce/packages`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/commerce/packages/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function postPackage(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(`${api.url}/commerce/packages`, data, config)
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deletePackage(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/commerce/packages/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}

export async function editPackage(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/commerce/packages/${data._id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

/**
 * END PACKAGE
 */

/**
 * QUEATION SET SCORE
 */

export async function postScore(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.post(
    `${api.url}/activity/gamification-scores`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function getScore(id = '', filter = [], limit = 999, offset = 0) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/activity/gamification-scores`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(
      `${api.url}/activity/gamification-scores/${id}`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function putScore(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/activity/gamification-scores`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

/**
 * GET MY PROFILE
 */

export async function getMyProfile() {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
    },
  }
  const request = await axios.get(`${api.url}/directory/profile/me`, {
    ...config,
  })
  if (request.status === 200) {
    return {
      status: 'ok',
      data: request.data.data,
      total: request.data.total,
    }
  } else {
    return { status: 'error', msg: "Can't load data" }
  }
}

/**
 * END OF GET MY PROFILE
 */

/**
 * GET DASHBOARD
 */

export async function getCounterQuestion(
  id = '',
  filter = [],
  limit = 999,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    if (
      item === 'subject-name' ||
      item === 'date-group' ||
      item === 'date-from' ||
      item === 'date-to' ||
      item === 'all-time'
    ) {
      const key = item
      Object.assign(params, { [key]: filter[item] })
    } else {
      const key = `filter[${item}]`
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/questions-counter`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(
      `${api.url}/content/questions-counter/${id}`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function getDashboardInsight(
  id = '',
  filter = [],
  limit = 999,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    if (
      item === 'subject-name' ||
      item === 'date-group' ||
      item === 'date-from' ||
      item === 'date-to' ||
      item === 'all-time'
    ) {
      const key = item
      Object.assign(params, { [key]: filter[item] })
    } else {
      const key = `filter[${item}]`
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
    'all-time': true,
    'page[sort]': 'order'
  })
  const request = await axios.get(`${api.url}/content/insights/dashboard/content-statistics`, {
    ...config,
    params: params,
  })
  if (request.status === 200) {
    return {
      status: 'ok',
      data: request.data.data,
      total: request.data.total,
    }
  } else {
    return { status: 'error', msg: "Can't load data" }
  }
}

export async function getSubjectByName(
  id = '',
  filter = [],
  limit = 999,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  if (Object.keys(filter).length > 0) {
    Object.keys(filter).map((item, index) => {
      const key = `filter[${item}]`
      Object.assign(params, { [key]: filter[item] })
    })
  }
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
    'filter[country]': region_id,
    'filter[school_id]': school_id,
  })
  if (id === '') {
    const request = await axios.get(
      `${api.url}/content/subjects/group-by-name`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(
      `${api.url}/content/subjects/group-by-name/${id}`,
      {
        ...config,
        params: params,
      }
    )
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

/**
 * END OF GET MY PROFILE
 */

/**
 * GET LESSON INSIGHT
 */
export async function getLessonInsight(
  id = '',
  filter = [],
  limit = 99,
  offset = 0
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'subject-name') {
      Object.assign(params, { 'subject-name': filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  const request = await axios.get(`${api.url}/content/insights/lessons`, {
    ...config,
    params: params,
  })
  if (request.status === 200) {
    return {
      status: 'ok',
      data: request.data.data,
      total: request.data.total,
    }
  } else {
    return { status: 'error', msg: "Can't load data" }
  }
}
/**
 * END LESSON INSIGHT
 */


/**
 * QUESTION CHALLANGE
 */
export async function getQuestionChallangeList(
  id = '',
  filter = [],
  limit = 10,
  offset = 1
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'sort') {
      Object.assign(params, { 'page[sort]': filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/challenges`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/challenges/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

export async function postChallange(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  // console.log(data);
  const request = await axios.post(
    `${api.url}/content/challenges`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function putChallange(id = '', data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.put(
    `${api.url}/content/challenges/${id}`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Updated!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}

export async function deleteChallange(id) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const request = await axios.delete(
    `${api.url}/content/challenges/${id}`,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', msg: 'The data was deleted succesfully!' }
  } else {
    return { status: 'error', msg: "Can't delete data" }
  }
}

export async function getLeaderboardChallangeList(
  id = '',
  filter = [],
  limit = 10,
  offset = 1
) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    Object.assign(params, { [key]: filter[item] })
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/content/challenges`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/content/challenges/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

/**
 * END QUESTION CHALLANGE
 */

/**
 * NOTIFICATION MESSAGE
 */
export async function postMessage(data = {}) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
      // debug: true
    },
  }
  const request = await axios.post(
    `${api.url}/notification/notifications/pushnotif/send/multicast`,
    data,
    config
  )
  if (request.status === 200) {
    return { status: 'ok', data: request.data.data, msg: 'Data Created!' }
  } else {
    return { status: 'error', msg: "Can't create data" }
  }
}
export async function getListMessage(id = '', filter = [], limit = 10, offset = 1) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'search') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/notification/notifications/pushnotif/multicast`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/notification/notifications/pushnotif/multicast/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
export async function getListMessageMulticast(id = '', filter = [], limit = 10, offset = 1) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'search') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/notification/notifications/pushnotif/multicast`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/notification/notifications/pushnotif/multicast/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}
/**
 * END NOTIFICATION MESSAGE
 */

/**
 * USER API
 */
export async function getUserByPhoneName(id = '', filter = [], limit = 10, offset = 1) {
  let auth = JSON.parse(localStorage.getItem('mindtrex_auth'))
  let school_id = localStorage.getItem('school_id')
  let region_id = localStorage.getItem('region_id')
  let config = {
    headers: {
      Authorization: `Bearer ${auth ? auth.access_token : null}`,
      School: school_id,
      Country: region_id,
    },
  }
  const params = {}
  Object.keys(filter).map((item, index) => {
    const key = `filter[${item}]`
    if (item === 'search') {
      Object.assign(params, { search: filter[item] })
    } else {
      Object.assign(params, { [key]: filter[item] })
    }
  })
  Object.assign(params, {
    'page[limit]': limit,
    'page[offset]': offset,
  })
  if (id === '') {
    const request = await axios.get(`${api.url}/directory/users`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return {
        status: 'ok',
        data: request.data.data,
        total: request.data.total,
      }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  } else {
    const request = await axios.get(`${api.url}/directory/users/${id}`, {
      ...config,
      params: params,
    })
    if (request.status === 200) {
      return { status: 'ok', data: request.data.data }
    } else {
      return { status: 'error', msg: "Can't load data" }
    }
  }
}

/**
 * END USER API
 */
