import _ from 'lodash'
import { getCountry, getSchool } from '../../../helper/api'
import routes from '../../../routes'
import _navs from '../../../_nav'
import * as types from './types'

export function getRegions(id = '') {
  let countries = []
  let profile = JSON.parse(localStorage.getItem('profile'))
  let request = getCountry()
  // console.log(profile)
  return (dispatch) =>
    request.then((response) => {
      response.data.map((item) => {
        countries.push({
          ...item,
          selected: item.id === id,
        })
      })
      dispatch({
        type: types.GET_COUNTRIES,
        data: countries,
      })
      return { countries }
    })
}

export function setRegion(region) {
  localStorage.setItem('region_id', region)
  this.getRegions(region)
  this.getSchools('', region)

  return {
    type: types.SET_REGION,
    data: region,
  }
}

export function getSchools(id = '', region = '') {
  let schools = []
  let profile = JSON.parse(localStorage.getItem('profile'))
  let filter = []
  filter['country'] = region
  let request = getSchool('', filter)

  return (dispatch, getState) =>
    request.then((response) => {
      const { signin } = getState()
      let accssable_school = signin.accessable_header.map(
        (res) => res.school_id
      )

      response.data.map((item) => {
        schools.push({
          ...item,
          selected: item.id === id,
        })
      })

      if (accssable_school[0] === '*') {
        dispatch({
          type: types.GET_SCHOOLS,
          data: schools,
        })
      } else {
        dispatch({
          type: types.GET_SCHOOLS,
          data: _.compact(_(schools).keyBy('id').at(accssable_school).value()),
        })
      }

      return { schools }
    })
}

export function setSchool(school, region) {
  this.getSchools(school, region)
  localStorage.setItem('school_id', school)
  return {
    type: types.SET_SCHOOL,
    data: school,
  }
}

export function generateAccess() {
  return (dispatch, getState) => {
    try {
      const { signin } = getState()

      function filterRoutes(list = [], data = [], result = []) {
        const items = _.cloneDeep(list)
        if (!items.length) {
          return result
        }
        const { routeName } = list[0]
        // console.log(items)
        let isAllowed = _.chain(data)
          .cloneDeep()
          .some((mod) => mod.route === routeName)
          .value()
        if (isAllowed || routeName === 'dashboard') {
          result.push(items[0])
        }
        list.shift()
        return filterRoutes(list, data, result)
      }

      function filterNavs(list = [], data = [], title, result = []) {
        if (!list.length) {
          return result
        }
        const { children, url, routeName, name, ...item } = list[0]

        const searchProperty = { route: routeName }
        if (title) {
          // searchProperty.name = name -> validate route name
        }
        let isAllowed = _.chain(data).some(searchProperty).value()
        if (isAllowed || routeName === 'dashboard') {
          const nav = { ...item, url, name, routeName }
          if (children) {
            const childNavs = filterNavs(children, data, routeName)
            title = null
            nav.children = childNavs
          }
          result.push(nav)
        }
        list.shift()
        return filterNavs(list, data, title, result)
      }

      let school_id = localStorage.getItem('school_id')
        ? localStorage.getItem('school_id')
        : signin.accessable_header[0].school_id
      const grantNavs = filterNavs(
        _.cloneDeep(_navs.items),
        signin.per_school_route[school_id]
      )
      const grantRoutes = filterRoutes(
        _.cloneDeep(routes),
        signin.per_school_route[school_id]
      )
      // console.log(grantRoutes)

      return { grantNavs, grantRoutes }
    } catch (error) {
      return new Promise((resolve) => ({
        status: false,
        statusCode: 500,
        data: { result: false, message: error.message },
      }))
    }
  }
}
